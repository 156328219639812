<template>
  <b-card>
    <div>
      <!-- search input -->
      <div class="custom-search d-flex align-items-center justify-content-end">
        <div
          class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
        >
          <!-- <b-form-input
            v-on:keyup="onSearch"
            v-model.lazy="searchTerm"
            placeholder="Search..."
            type="text"Create
            class="d-inline-block mr-sm-1 mb-1 mb-sm-0"
          /> -->
          <template
            v-if="$permissionAbility(EMPLOYEE_TYPES_CREATE, permissions)"
          >
            <b-button
              class="flex-shrink-0 mr-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-on:click="showModal"
            >
              Create Type
            </b-button>
          </template>
          <template
            v-if="$permissionAbility(EMPLOYEE_TYPES_TRASH_ACCESS, permissions)"
          >
            <b-button
              class="flex-shrink-0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              @click="goToTrash"
            >
              Archive
            </b-button>
          </template>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
        :rows="rows"
        :columns="columns"
        :is-loading.sync="isLoading"
        :sort-options="{
          enabled: false,
          multipleColumns: true,
          initialSortBy: [{ field: 'name', type: 'asc' }],
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        styleClass="vgt-table striped"

      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: head -->
          <!-- <span v-if="props.column.field === 'head'">
            <template v-if="props.row.head_id != null">
              <b-avatar :src="props.row.head.data.avatar" class="mx-1" />
              <span class="text-nowrap">{{ props.row.head.data.name }}</span>
            </template>
          </span> -->

          <span v-b-tooltip.hover :title='props.row?.description' v-if="props.column.field === 'format_description'">
            {{ truncate(props.row.description) }}
          </span>

          <span v-if="props.column.field === 'action'">

            <template v-if="$permissionAbility(EMPLOYEE_TYPES_EDIT, permissions)">
              <span @click="onShow(props.row)">
                <feather-icon
                  v-b-tooltip.hover
                  icon="Edit2Icon"
                  class="mr-50 custom-icon cursor-pointer"
                  title="Edit"
                  variant="warning"
                  size="16"
                />
              </span>
            </template>

            <template
                v-if="$permissionAbility(EMPLOYEE_TYPES_DELETE, permissions)"
            >
              <span @click="onDelete(props.row)">

                <feather-icon
                    v-b-tooltip.hover
                    icon="TrashIcon"
                    class="mr-50 custom-icon cursor-pointer"
                    variant="danger"
                    title="Delete"
                    size="16"
                />
              </span>
            </template>

          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '25', '50', '100', '500']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>

    <b-modal
      id="modal-employee-type-form"
      centered
      :title="modelType == 'editModel' ? 'Edit' : 'Create'"
      hide-footer
      @hidden="hiddenModal"
      no-close-on-backdrop
    >
      <validation-observer ref="employeeTypeValidation">
        <b-form v-on:submit.prevent="validationForm">
          <!-- name -->
          <b-form-group label="Title" label-for="name" class="required-label">
            <validation-provider
              #default="{ errors }"
              name="name"
              vid="name"
              rules="required|max:255"
            >
              <b-form-input
                id="name"
                type="text"
                v-model="name"
                :state="errors.length > 0 ? false : null"
                placeholder="Employee Type"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- select leave_types-->
          <b-form-group label="Leave Types" label-for="leave-types-id">
            <ValidationProvider
              name="leave_types_id"
              v-slot="{ errors }"
              vid="leave_types_id"
            >
              <v-select
                id="leave-types-id"
                placeholder="Choose multiple leave type"
                v-model="selectLeaveTypesIds"
                :options="leaveTypesOption"
                :reduce="(option) => option.id"
                label="name"
                multiple
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- description -->
          <b-form-group label="Description" label-for="description">
            <validation-provider
              #default="{ errors }"
              name="description"
              vid="description"
            >
              <b-form-textarea
                id="description"
                v-model="description"
                :state="errors.length > 0 ? false : null"
                placeholder="Description"
                rows="3"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- loading button -->
          <template v-if="isEmployeeTypeFormSubmitLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
              type="submit"
              class="float-right"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BFormTextarea,
  BSpinner, VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import {
  EMPLOYEE_TYPES_CREATE,
  EMPLOYEE_TYPES_EDIT,
  EMPLOYEE_TYPES_DELETE,
  EMPLOYEE_TYPES_TRASH_ACCESS,
} from "@/helpers/permissionsConstant";

export default {
  name: "EmployeeTypesView",
  components: {
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BFormTextarea,
    BSpinner,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      EMPLOYEE_TYPES_CREATE,
      EMPLOYEE_TYPES_EDIT,
      EMPLOYEE_TYPES_DELETE,
      EMPLOYEE_TYPES_TRASH_ACCESS,
      modelType: "",
      employeeTypeId: "",
      name: "",
      description: "",
      isLoading: false,
      //   selectHeadId: "",
      //   headIdOption: [],
      selectLeaveTypesIds: [],
      leaveTypesOption: [],
      pageLength: 10,
      columns: [
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Description",
          field: "format_description",
          sortable: false,
          formatFn: this.truncate,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      isEmployeeTypeFormSubmitLoading: false,
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  async created() {
    await this.loadItems();

    try {
      const [getLeaveTypesAll] = await Promise.all([
        this.getLeaveTypesAll(),
        this.loadItems(),
      ]);

      //  getLeaveTypesAll
      this.leaveTypesOption = (getLeaveTypesAll?.data?.data || []).map(
        (item) => {
          return {
            name: item?.name,
            id: item?.id,
          };
        }
      );
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    // slice description
    truncate(data) {
      const maxLength = 25; // Set your maximum length here
      if (data && data.length > maxLength) {
        return data.slice(0, maxLength) + "...";
      }
      return data;
    },
    goToTrash() {
      this.$router.push({ name: "employee-types-trash" });
    },
    showModal() {
      this.$bvModal.show("modal-employee-type-form");
    },
    hiddenModal() {
      this.$bvModal.hide("modal-employee-type-form");
      this.resetModal();
    },
    resetModal() {
      this.modelType = "";
      this.employeeTypeId = "";
      this.name = "";
      this.description = "";
      this.selectLeaveTypesIds = "";
    },
    async onShow(value) {
      this.modelType = "editModel";

      this.employeeTypeId = value?.id ? +value?.id : "";
      this.name = value?.name;
      this.description = value?.description;
      this.selectLeaveTypesIds = (value?.leave_types || []).map(
        (item) => item?.id
      );

      this.showModal();
    },
    async onDelete(row) {
      this.$swal({
        title: "Warning!",
        text: `Are You Sure, You Want To Delete ${row.name}?`,
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Delete",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
      try {
        await this.$api.delete(`/api/employee-types/${row.id}`);

        this.loadItems();

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "BellIcon",
            variant: "success",
            text: "Moved to Archive Successfully",
          },
        });
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    }
      });
    },

    // onSearch() {
    //   if (this.delayTimer) {
    //     clearTimeout(this.delayTimer);
    //     this.delayTimer = null;
    //   }

    //   this.delayTimer = setTimeout(() => {
    //     this.loadItems();
    //   }, 1000);
    // },

    // updateParams(newProps) {
    //   this.serverParams = Object.assign({}, this.serverParams, newProps);
    // },

    // onPageChange(params) {
    //   this.updateParams({ page: params.currentPage });
    //   this.loadItems();
    // },

    // onPerPageChange(params) {
    //   this.updateParams({ perPage: params.currentPerPage });
    //   this.loadItems();
    // },

    // onSortChange(params) {
    //   this.updateParams({
    //     sort: params,
    //   });
    //   this.loadItems();
    // },

    // onColumnFilter(params) {
    //   this.updateParams(params);
    //   this.loadItems();
    // },

    async getLeaveTypesAll() {
      return await this.$api.get("api/leave-types/current-year-all");
    },
    async loadItems() {
      try {

        this.isLoading = true

        const resEmployeeTypes = await this.$api.get("api/employee-types");
        await this.$api.get("api/employee-types");

        const data = resEmployeeTypes?.data?.data;
        // const meta = resEmployeeTypes?.data?.meta;

        // this.totalRecords = meta?.pagination?.total;
        this.rows = data;

        // const resUser = await this.$api.get("api/users");

        this.isLoading = false

        // this.headIdOption = (resUser?.data?.data || []).map((item) => {
        //   return {
        //     name: item.name,
        //     id: item.id,
        //   };
        // });
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    validationForm: async function () {
      this.$refs.employeeTypeValidation.validate().then(async (success) => {
        if (success) {
          try {
            if (this.modelType == "editModel") {
              this.isEmployeeTypeFormSubmitLoading = true;
              await this.$api.put(
                `/api/employee-types/${this.employeeTypeId}`,
                {
                  name: this.name,
                  description: this.description,
                  leave_type_id: this.selectLeaveTypesIds,
                }
              );
              this.isEmployeeTypeFormSubmitLoading = false;
              this.loadItems();
              this.hiddenModal();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Successfully Updated",
                },
              });
            } else {
              this.isEmployeeTypeFormSubmitLoading = true;
              await this.$api.post(
                "/api/employee-types",

                {
                  name: this.name,
                  description: this.description,
                  leave_type_id: this.selectLeaveTypesIds,
                }
              );
              this.isEmployeeTypeFormSubmitLoading = false;
              this.hiddenModal();

              this.loadItems();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Successfully Created",
                },
              });
            }
          } catch (error) {
            this.isEmployeeTypeFormSubmitLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.employeeTypeValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
.actionBtn span{
  padding: 8px;
  cursor: pointer;
}
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
